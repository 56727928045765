import { MDBCol, MDBContainer, MDBRow, MDBTypography } from "mdb-react-ui-kit";
import React, { useEffect } from "react";
import { analyticsCountReq } from "../Request";
import useStore from "../Store";

const Summary = () => {
  const set = useStore((s) => s.set);
  const access_token = useStore((s) => s.access_token);
  const affiliateCode = useStore((s) => s.profile?.affiliateCode);
  const analyticsCount = useStore((s) => s.analyticsCount);

  const getCount = async () => {
    const fetchCounts = await analyticsCountReq(access_token, affiliateCode);
    if (fetchCounts.error) return;

    set((s) => {
      s.analyticsCount = fetchCounts;
    });
  };

  useEffect(() => {
    affiliateCode && getCount();
  }, [affiliateCode]);

  return (
    <div className="mb-5">
      <MDBContainer>
        <MDBRow>
          {analyticsCount &&
            analyticsCount.map((ac, i) => (
              <MDBCol size="3" key={i}>
                <div className={`p-3 shadow-5 text-md-start pb-4 rounded-3 bg-white summary-box tile-box-${i}`}>
                  <MDBTypography tag="h4" className="mb-0">
                     {
                      ac.type === "totalRevenue" || ac.type === "totalCommission" ? "$" + ac.count?.toFixed(2) : "" + ac.count
                     }
                  </MDBTypography>
                  {ac.type === "totalOrders" && (
                      <span className="">Total Product Purchased</span>
                  )}
                  {ac.type === "pageview" && (
                    <span className="">Total Page Visit</span>
                  )}
                  {ac.type === "totalRevenue" && (
                    <span className="">Total Revenue Generated</span>
                  )}
                  {ac.type === "totalCommission" && (
                    <span className="">Net Commission</span>
                  )}
                </div>
              </MDBCol>
            ))}
        </MDBRow>
      </MDBContainer>
    </div>
  );
};

export default Summary;
