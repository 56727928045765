import React from "react";
import History from "./components/History";
import Header from "./components/Header";

export default function FullHistory() {
  return (
    <>
      <Header />
      <History />
    </>
  );
}
