import { MDBCol, MDBContainer, MDBRow, MDBTypography } from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { countPerDayReq } from "../Request";
import useStore from "../Store";
import Chart from "./Chart";

function PerDayChart() {
  const access_token = useStore((s) => s.access_token);
  const affiliateCode = useStore((s) => s.profile?.affiliateCode);

  const [salePerDay, setSalePerDay] = useState([]);
  const [eventsPerDay, setEventsPerDay] = useState([]);

  const getPerdayCount = async () => {
    const fetchPerDayCount = await countPerDayReq(access_token, affiliateCode);
    //console.log(fetchPerDayCount);
    if (fetchPerDayCount.error) return;

    setSalePerDay(fetchPerDayCount.sale);
    setEventsPerDay(fetchPerDayCount.event);
  };

  useEffect(() => {
    affiliateCode && getPerdayCount();
  }, [affiliateCode]);

  return (
    <div className="text-md-start">
      <MDBContainer>
        <MDBRow className="g-3">
          <MDBCol size="12" md="6">
            <div className="shadow-5 p-3 rounded-3 bg-dark">
              <MDBTypography tag="h5" className="text-white bg-dark">
                Comission Breakdown
              </MDBTypography>
              <Chart perDayData={salePerDay} label="Sales" />
            </div>
          </MDBCol>
          <MDBCol size="12" md="6">
            <div className="shadow-5 p-3 rounded-3 bg-dark">
              <MDBTypography tag="h5" className="text-white bg-dark">
                Clicks Breakdown
              </MDBTypography>
              <Chart perDayData={eventsPerDay} label="Total Page Visits" />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}

export default PerDayChart;
