import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Dashboard from "./Dashboard";
import FullHistory from "./FullHistory";

export default function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/history">
          <FullHistory />
        </Route>
        <Route path="/">
          <Dashboard />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
