import React, { useCallback, useEffect, useState } from "react";
import { MDBCol, MDBContainer, MDBRow, MDBBtn } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import useStore from "../Store";

import EventItem from "./EventItem";

import { evetnReq } from "../Request";

export default function () {
  const [events, setEvents] = useState([]);
  const access_token = useStore((s) => s.access_token);

  const getEvents = async () => {
    const fetchedEvents = await evetnReq(access_token, 1, 5, "all");
    setEvents([...fetchedEvents.data]);
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <section className="my-5 text-start mb-0 pb-5">
      <MDBContainer>
        <div className="shadow-5 p-4 rounded-3 bg-dark">
          <MDBRow className="mb-4">
            <MDBCol size="" className="text-md-start">
              <h4 className="text-white">Recent Event History</h4>
            </MDBCol>
            <MDBCol className="text-end">
              <Link to="/history">
                <MDBBtn
                  outline
                  className="fw-bold btn-pink"
                >
                  Full History
                </MDBBtn>
              </Link>
            </MDBCol>
          </MDBRow>

          <MDBRow className="border-bottom border-dark">
            <MDBCol size="3" className="py-2">
              <span className="text-white ">Date and Time</span>
            </MDBCol>
            <MDBCol size="5" className="py-2">
              <span className="text-white ">Event</span>
            </MDBCol>
            <MDBCol size="2" className="py-2">
              <span className="text-white ">Location</span>
            </MDBCol>
            <MDBCol size="2" className="py-2">
              <span className="text-white ">Commission</span>
            </MDBCol>
          </MDBRow>

          {events.map((ev, i) => (
            <EventItem event={ev} key={i} />
          ))}
        </div>
      </MDBContainer>
    </section>
  );
}
