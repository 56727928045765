import React, { useEffect } from "react";
import Header from "./components/Header";
import Analytics from "./components/Analytics";
import ShortHistory from "./components/ShortHistory";
import useStore from "./Store";

import { profileReq } from "./Request";

const Dashboard = () => {
  const set = useStore((s) => s.set);
  const access_token = useStore((s) => s.access_token);
  const profile = useStore((s) => s.profile);

  console.log(access_token)
  console.log(profile)

  const fetchProfile = async () => {
    
    const pro = await profileReq(access_token);
    if(!pro.error){
      
      set((s) => {
        s.profile = pro;
      });
    }else if(pro?.status===401){
      
      set((s) => {
        s.profile = null;
        s.access_token = null;
      });
      localStorage.clear()
    }

  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <>
      <Header />
      <Analytics />
      <ShortHistory />
    </>
  );
};

export default Dashboard;
