import axios from "axios";
const server = process.env.REACT_APP_SERVER_ADDR;;

export const loginReq = async (loginDetails) => {
  try {
    const res = await axios.post(`${server}/api/users/login`, loginDetails, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      return {
        error: true,
        status: error.response.status,
      };
    }
  }
};

export const profileReq = async (access_token) => {
  try {
    const res = await axios.get(`${server}/api/users/profile`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      return {
        error: true,
        status: error.response.status,
      };
    }
  }
};

export const evetnReq = async (access_token, page, limit, type) => {
  try {
    const res = await axios.get(
      `${server}/api/event?page=${page}&limit=${limit}&type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    if (error.response) {
      return {
        error: true,
        status: error.response.status,
      };
    }
  }
};

export const analyticsCountReq = async (access_token, affiliateCode) => {
  try {
    const res = await axios.post(
      `${server}/api/event/count`,
      { affiliateCode: affiliateCode },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      return {
        error: true,
        status: error.response.status,
      };
    }
  }
};

export const countPerDayReq = async (access_token, affiliateCode) => {
  try {
    const res = await axios.post(
      `${server}/api/event/perday`,
      { affiliateCode: affiliateCode },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    if (error.response) {
      return {
        error: true,
        status: error.response.status,
      };
    }
  }
};


export const editUserReq = async (access_token, id, newUserDetails) => {
  try {
    const res = await axios.post(`${server}/api/users/edit/${id}`, newUserDetails, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
    });
    return res.data;
  } catch (error) {
    if (error.response) {
      console.log(error.response);
      return {
        error: true,
        status: error.response.status,
        message: error.response.data.error,
      };
    }
  }
};