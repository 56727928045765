import React, { useEffect } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBTypography } from "mdb-react-ui-kit";
import PerDayChart from "./PerDayChart";
import Summary from "./Summary";

export default function () {
  return (
    <section className="my-5">
      <div className="header mb-4 text-md-start">
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              <h4 className="text-white fw-bold">Affiliate Snapshot</h4>
              <p>Here's a breakdown of your affiliate activity.</p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
      <Summary />
      <PerDayChart />
    </section>
  );
}
