import React, { useCallback, useEffect, useState } from "react";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBBtn,
  MDBTypography,
} from "mdb-react-ui-kit";
import { Link, useHistory } from "react-router-dom";
import HistoryUtility from "./HistoryUltility";

import useStore from "../Store";

import EventItem from "./EventItem";

import { evetnReq } from "../Request";

export default function () {
  const history = useHistory();
  const [events, setEvents] = useState([]);
  const access_token = useStore((s) => s.access_token);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [type, setType] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [pageLength, setPageLength] = useState(0);

  const getEvents = async () => {
    const fetchedEvents = await evetnReq(access_token, page, limit, type);

    if (fetchedEvents.error) return;

    console.log(fetchedEvents);
    setEvents([...fetchedEvents.data]);
    setPageLength((page - 1) * limit + fetchedEvents.data.length);
    setTotalCount(fetchedEvents.total);
    setLastPage(fetchedEvents.lastPage);
    history.push({ search: `?type=${type}&page=${page}&limit=${limit}` });
  };

  useEffect(() => {
    getEvents();
  }, [page, limit, type]);

  const nextPage = useCallback(() => {
    if (page < lastPage) setPage((page) => page + 1);
    console.log(page);
  }, [page, lastPage]);

  const previousPage = useCallback(() => {
    if (page > 1) setPage((page) => page - 1);
    console.log(page);
  }, [page]);

  const changeLimit = useCallback((e) => {
    const newLimit = parseInt(e.target.value);
    setLimit(newLimit);
    setPage(page);
  }, []);

  const changeType = useCallback((e) => {
    setType(e.target.value);
    setPage(1);
  }, []);

  return (
    <section className="py-5 text-start">
      <section className="mb-4">
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              <div className="d-flex align-items-center">
                <Link to="/">
                  <MDBBtn
                    outline
                    className="fw-bold btn-pink"
                  >
                    <i className="fas fa-chevron-left"></i>&nbsp;Back
                  </MDBBtn>
                </Link>
                <MDBTypography variant="h4" className="mb-0 ms-4 text-white">
                  Full Event History
                </MDBTypography>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
      <MDBContainer>
        <div className="shadow-5 p-4 rounded-3 bg-dark">
          <MDBRow className="mb-4 align-items-center">
            <MDBCol>
              <span className="d-inline-block me-2">Filter: </span>
              <select onChange={changeType}>
                <option value="all">All</option>
                <option value="pageview">Pageview</option>
                <option value="sale">Sale</option>
              </select>
            </MDBCol>
            <MDBCol>
              <HistoryUtility
                nextPage={nextPage}
                previousPage={previousPage}
                changeLimit={changeLimit}
                limit={limit}
                page={page}
                totalCount={totalCount}
                pageLength={pageLength}
                lastPage={lastPage}
              />
            </MDBCol>
          </MDBRow>

          <MDBRow className="border-bottom border-dark">
            <MDBCol size="3" className="py-2">
              <span className="text-white ">Date and Time</span>
            </MDBCol>
            <MDBCol size="5" className="py-2">
              <span className="text-white ">Event</span>
            </MDBCol>
            <MDBCol size="2" className="py-2">
              <span className="text-white ">Location</span>
            </MDBCol>
            <MDBCol size="2" className="py-2">
              <span className="text-white ">Commission</span>
            </MDBCol>
          </MDBRow>

          {events.map((ev, i) => (
            <EventItem event={ev} key={i} />
          ))}

          <MDBRow className="mt-4">
            <MDBCol></MDBCol>
            <MDBCol>
              <HistoryUtility
                nextPage={nextPage}
                previousPage={previousPage}
                changeLimit={changeLimit}
                limit={limit}
                page={page}
                totalCount={totalCount}
                pageLength={pageLength}
              />
            </MDBCol>
          </MDBRow>
        </div>
      </MDBContainer>
    </section>
  );
}
