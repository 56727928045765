import React from "react";
import { MDBRow, MDBCol } from "mdb-react-ui-kit";
import date from "date-and-time";

const EventItem = ({ event }) => {
  return (
    <>
      {event.type === "pageview" && <EventPageviewItem event={event} />}
      {event.type === "sale" && <EventSaleEvent event={event} />}
    </>
  );
};

export default EventItem;

const EventSaleEvent = ({ event }) => {
  const eventCreatedAt = new Date(event.createdAt);
  const eventDate = date.format(eventCreatedAt, "ddd, MMM DD YYYY");
  const eventTime = date.format(eventCreatedAt, "hh:mm A");

  return (
    <MDBRow className="border-bottom border-gray history-item">
      <MDBCol size="3" className="py-3">
        <span className="text-date d-block">
          {/* Sun Jun 16th 2021 */}
          {eventDate}
        </span>
        <span className="text-time">{eventTime}</span>
      </MDBCol>
      <MDBCol size="5" className="py-3">
        <span className="text-date d-block">
          A visitor just placed an order
        </span>
      </MDBCol>
      <MDBCol size="2" className="py-3">
        <span className="text-date">{event?.location}</span>
      </MDBCol>
      <MDBCol size="2" className="py-3">
        <span className="text-white">${event.commission?.toFixed(2)}</span>
      </MDBCol>
    </MDBRow>
  );
};

const EventPageviewItem = ({ event }) => {
  const eventUrl = new URL(event.url);

  const eventCreatedAt = new Date(event.createdAt);
  const eventDate = date.format(eventCreatedAt, "ddd, MMM DD YYYY");
  const eventTime = date.format(eventCreatedAt, "hh:mm A");

  return (
    <MDBRow className="border-bottom border-gray history-item">
      <MDBCol size="3" className="py-3">
        <span className="text-date d-block">
          {/* Sun Jun 16th 2021 */}
          {eventDate}
        </span>
        <span className="text-time">{eventTime}</span>
      </MDBCol>
      <MDBCol size="5" className="py-3">
        <span className="text-date d-block">
          A visitor visited a page
        </span>
        <span className="text-white">{eventUrl.pathname}</span>
      </MDBCol>
      <MDBCol size="2" className="py-3">
        <span className="text-date">{event?.location}</span>
      </MDBCol>
      <MDBCol size="2" className="py-3"></MDBCol>
    </MDBRow>
  );
};
