import React, { useCallback, useEffect, useState } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalFooter,
  MDBModalHeader,
  MDBModalTitle,
  MDBTypography,
  MDBValidation,
  MDBInput,
  // MDBDropdownToggle,
  // MDBDropdownMenu,
  // MDBDropdownItem,
  // MDBDropdownLink,
} from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import { editUserReq } from "../Request";

import useStore from "../Store";

export default function () {
  const set = useStore((s) => s.set);
  const first_name = useStore((s) => s.profile?.first_name);
  const email = useStore((s) => s.profile?.email);
  const last_name = useStore((s) => s.profile?.last_name ?? "");
  let aff = useStore((s) => s.profile?.affiliateCode ?? "");
  const [affiliate_code, setAffiliateCode] = useState(aff);
  const access_token = useStore((s) => s?.access_token ?? "");
  const user_id = useStore((s) => s.profile?.id ?? "");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [basicModal, setBasicModal] = useState(false);

  useEffect(()=>{setAffiliateCode(aff)}, [aff])
  ///console.log("render")
  const handleLogout = useCallback(() => {
    set((s) => {
      s.access_token = null;
      s.profile = null;
    });
  }, []);

  const toggleShow = useCallback(() => {
    setBasicModal((basicModal) => !basicModal);
  }, []);

  function copyToClipboard() {
    let text = process.env.REACT_APP_WEBSITE + "?affiliateCode=" + affiliate_code;
    navigator.clipboard.writeText(text)
      .then(function() {
        console.log('Text copied to clipboard');
        setSuccess("Text copied to clipboard!");
        setTimeout(()=>{setSuccess("")}, 2500)
      })
      .catch(function(err) {
        console.error('Failed to copy text: ', err);
      });
  }

  const submitAffliateCode = async() => {
    let value = document.getElementById("new_affiliate_code").value;
    if(value){
      console.log(access_token, user_id, value)
      const createdUser = await editUserReq(access_token, user_id, 
        {
          affiliateCode: value,
          email: email,
          oldAffiliateCode: affiliate_code
        });
      console.log("res", createdUser);
      if(createdUser?.status == 409){
        setError("Affiliate Code already exists. Please try again with a new Code.");
      }else{
        setSuccess("Affliate Code Updated successfully");
        setBasicModal(false);
        setAffiliateCode(value);
      }
    }else{
      setError("Please provide a valid value");
    }
    setTimeout(()=>{setSuccess("")}, 3500)
    setTimeout(()=>{setError("")}, 3500)
  }
  

  return (
    <header>
      <MDBContainer>
        <div className="py-3 border-bottom border-gray">
          <MDBRow>
            <MDBCol
              size="1"
              className="header-col__logo d-flex justify-content-start align-items-center"
            >
              <Link to="/">
                <img className="logo" src={logo} />
              </Link>
            </MDBCol>
            <MDBCol
              size="5"
              className="header-col__logo d-flex justify-content-start align-items-center ps-4"
            >
              <h5 className="fw-bold text-white ps-4 left-white">Affiliate Dashboard</h5>
            </MDBCol>
            <MDBCol
              size="6"
              className="header-col__button d-flex justify-content-end align-items-center"
            >
              {/* <MDBDropdown className="shadow-0">
                <MDBDropdownToggle color="light" className="shadow-0" noRipple>
                  Howdy, {first_name}
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem>
                    <MDBDropdownLink href="#">Action</MDBDropdownLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <MDBDropdownLink href="#">Another action</MDBDropdownLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <MDBDropdownLink href="#">
                      Something else here
                    </MDBDropdownLink>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown> */}
              <div className="div-with-blended-borders d-flex p-1">
                <div className="ps-1 pe-3 text-muted fs-7">
                  Referal Code
                </div>
                <div className="ps-1 pe-1">
                  {affiliate_code}
                </div>
                <div className="ps-1 pe-1">
                  <i onClick={copyToClipboard} className="fa fa-copy cursor-pointer"></i>
                </div>
                <div className="ps-2 pe-1">
                  <i className="fa fa-edit cursor-pointer" onClick={()=>{setBasicModal(true)}}></i>
                </div>
              </div>
              {/* <p className="mb-0 mx-4 fw-bold">Code: {affiliate_code}</p> */}
              <p className="mb-0 mx-4 fw-bold">{first_name} {last_name}</p>
              <MDBBtn
                className="fw-bold btn-pink " 
                onClick={handleLogout}
              >
                <i className="fa fa-arrow-right pe-2"> </i>Logout
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </div>
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        {success && (
          <div className="alert alert-success copy-alert" role="alert">
            {success}
          </div>
        )}
        <MDBModal
          show={basicModal}
          getOpenState={(e) => setBasicModal(e)}
          tabIndex="-1"
        >
          <MDBModalDialog>
            <MDBModalContent>
              <MDBModalHeader className="bg-dark text-white d-flex justify-content-center">
                <MDBModalTitle>Edit Affiliate Code</MDBModalTitle>
              </MDBModalHeader>
              <MDBModalBody>
                <input type="text" required placeholder={affiliate_code} className="form-control" id="new_affiliate_code"/>

                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}
                {success && (
                  <div className="alert alert-success copy-alert" role="alert">
                    {success}
                  </div>
                )}
              </MDBModalBody>

              <MDBModalFooter>
                <MDBBtn className="btn-black" onClick={toggleShow}>Close</MDBBtn>
                <MDBBtn className="btn-pink" onClick={submitAffliateCode}>
                  Yes
                </MDBBtn>
              </MDBModalFooter>
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </MDBContainer>
    </header>
  );
}
