import React, { useState } from "react";

import Login from "./components/Login";
import Dashboard from "./Dashboard";
import Router from "./Router";

import useStore from "./Store";

function App() {
  const profile = useStore((s) => s.profile);
  console.log(profile)

  return <div className="App">{profile ? <Router /> : <Login />}</div>;
}

export default App;
